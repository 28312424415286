import { ThemeProvider as EmotionThemeProvider } from '@emotion/react'
import React, { memo } from 'react'

import { MediaContextProvider, mediaStyle } from './media'

export type Theme = typeof theme

export const theme = {
  colors: {
    variants: {
      neutralLight5: '#FFFFFF',
      neutralLight4: '#F7F5F5',
      neutralDark2: '#122223',
      neutralDark1: '#000000',
      primaryDark: '#122223',
      primaryLight: '#FFE5CE',
      danger: '#CC5858',
    },
  },
  fontFamily: {
    heading: "'Bodoni Moda', serif",
    paragraph: "'Montserrat', sans-serif",
  },
}

export const ThemeProvider = memo(function ThemeProvider({ children }: any) {
  return (
    <EmotionThemeProvider theme={theme}>
      <style type="text/css">{mediaStyle}</style>
      <MediaContextProvider disableDynamicMediaQueries>
        {children}
      </MediaContextProvider>
    </EmotionThemeProvider>
  )
})
